import { PermanentShopEnum, PermanentShopType } from 'types/types';

export const getPermanentShops = (): PermanentShopType[] => [
  {
    id: PermanentShopEnum.EXTRA_TIME_DEALS,
    name: 'Extra Time Deals',
    shortName: 'XTD',
    color: '',
    blurb: [],
  },
  {
    id: PermanentShopEnum.EVERYDAY_ESSENTIALS,
    name: 'Everyday Essentials',
    shortName: 'Essentials',
    color: '',
    blurb: [],
  },
];
